.footer-footer {
	flex: 0 0 auto;
	width: 100%;
	height: auto;
	display: flex;
	position: sticky;
	left: 0;
	bottom: 0;
	align-items: center;
	justify-content: center;
}

.footer-separator {
	flex: 0 0 auto;
	width: 100%;
	height: 0px;
	display: flex;
	margin-top: var(--dl-space-space-twounits);
	align-items: flex-start;
	margin-left: 0px;
	border-color: var(--dl-color-gray-900);
	border-style: solid;
	border-width: 1px;
	margin-right: 0px;
	margin-bottom: var(--dl-space-space-twounits);
	flex-direction: row;
	border-top-width: 0px;
	border-left-width: 0px;
	border-right-width: 0px;
}

.footer-container {
	flex: 0 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-around;
}

.footer-image {
	width: 30vw;
	height: 7vh;
	object-fit: fit;
}

.footer-icon-group {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
}

.footer-icon {
	width: 24px;
	height: 24px;
	margin-right: var(--dl-space-space-unit);
}

.footer-icon:hover {
	cursor: pointer;
}

@media(max-width: 767px) {
	.footer-footer1 {
		padding-left: var(--dl-space-space-twounits);
		padding-right: var(--dl-space-space-twounits);
	}

	.footer-separator {
		margin-top: var(--dl-space-space-oneandhalfunits);
		margin-left: 0px;
		margin-right: 0px;
		margin-bottom: var(--dl-space-space-oneandhalfunits);
	}

	.footer-container {
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
	}
}

@media(max-width: 479px) {
	.footer-footer1 {
		padding: var(--dl-space-space-unit);
	}

	.footer-separator {
		margin-top: var(--dl-space-space-oneandhalfunits);
		margin-bottom: var(--dl-space-space-oneandhalfunits);
	}

	.footer-container {
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
	}

	.footer-text {
		text-align: center;
	}
}