.accordion-button {
	background-color: var(--dl-color-primary-reduserd) !important;
	border: 2px solid !important;
	border-color: white !important;
	color: white !important;
}

.accordion-button:hover {
	transform: scale(1.02);
}

.alert-prix {
	background-color: var(--dl-color-primary-myblue) !important;
	color: white !important;
}

.accordion-button::after {
	content: '▼' !important;
	background-image: none !important;
}