.home-container {
	width: 100%;
	display: flex;
	overflow: auto;
	min-height: 100vh;
	align-items: center;
	flex-direction: column;
}

.home-hero {
	flex: 0 0 auto;
	width: 100%;
	height: auto;
	display: flex;
	margin-top: 11vh;
	align-items: flex-start;
	justify-content: center;
}

.home-hero1 {
	display: flex;
	background-size: cover;
	background-color: transparent;
	background-position: center;
}

.background-image {
	background-color: transparent;
	background-image: url('/external/globe_banniere.jpg');
	background-size: cover;
	background-position: center;
	height: 40vh !important;
	width: 100%;
}

.home-container01 {
	gap: var(--dl-space-space-oneandhalfunits);
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
}

.home-hero-heading {
	color: var(--dl-color-gray-500);
	font-size: 48px;
	max-width: 800px;
	text-align: center;
	font-family: "Fjalla One";
	font-weight: 700;
	line-height: 125%;
}

.home-hero-sub-heading {
	color: var(--dl-color-gray-500);
	font-size: 18px;
	text-align: center;
}

.home-features {
	width: 100%;
	display: flex;
	padding: 60px;
	max-width: var(--dl-size-size-maxwidth);
	align-items: center;
	flex-direction: column;
}

.home-text11 {
	color: var(--dl-color-primary-reduserd);
	font-family: "Bebas Neue";
	font-style: italic;
	font-size: 4rem;
	margin-top: var(--dl-space-space-halfunit);
	;
}

.home-text14 {
	color: var(--dl-color-gray-700);
	width: 70%;
	font-size: 1.15rem;
	text-align: center;
}

.home-container02 {
	width: 100%;
	display: grid;
	grid-gap: var(--dl-space-space-unit);
	margin-top: var(--dl-space-space-twounits);
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.home-button {
	background-color: var(--dl-color-primary-reduserd);
	color: white;
}

.home-features1 {
	flex: 0 0 auto;
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-features2 {
	gap: var(--dl-space-space-threeunits);
	width: 100%;
	display: flex;
	max-width: var(--dl-size-size-maxwidth);
	align-items: center;
	padding-top: var(--dl-space-space-fourunits);
	padding-left: var(--dl-space-space-threeunits);
	padding-right: var(--dl-space-space-threeunits);
	flex-direction: column;
	padding-bottom: var(--dl-space-space-fourunits);
	justify-content: flex-start;
}

.home-container03 {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
}

.home-features-sub-heading {
	font-size: 18px;
	text-align: center;
}

.home-container04 {
	width: 100%;
	display: grid;
	grid-gap: var(--dl-space-space-oneandhalfunits);
	grid-template-columns: 1fr 1fr;
}

.home-steps {
	width: 100%;
	display: flex;
	padding: var(--dl-space-space-oneunit);
	max-width: var(--dl-size-size-maxwidth);
	align-items: center;
	flex-direction: column;
}

.home-text34 {
	color: var(--dl-color-primary-myblue);
	font-size: 3rem;
	padding-top: 2%;
	font-family: 'Bebas Neue';
}

.home-text35 {
	color: var(--dl-color-gray-700);
	font-size: 1.15rem;
	text-align: center;
	margin-bottom: var(--dl-space-space-twounits);
}

.home-container05 {
	flex: 0 0 auto;
	width: 100%;
	display: flex;
	align-items: flex-start;
	margin-top: 3%;
	flex-direction: row;
	justify-content: space-around;
}

.home-step {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.video-accueil {
	height: 50vh;
	width: 100%;
}

@media (max-width: 1200px) {
	.home-container02 {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media(max-width: 991px) {

	.home-hero {
		margin-top: 12vh;
	}

	.home-hero1 {
		flex-direction: column;
	}

	.background-image {
		height: 25vh;
	}

	.home-container01 {
		align-items: center;
		margin-right: 0px;
		margin-bottom: var(--dl-space-space-twounits);
		padding-right: 0px;
	}

	.home-hero-heading {
		text-align: center;
		font-size: 37px;
	}

	.home-hero-sub-heading {
		text-align: center;
		padding-left: var(--dl-space-space-threeunits);
		padding-right: var(--dl-space-space-threeunits);
	}

	.home-text11 {
		text-align: center;
	}

	.home-text14 {
		text-align: center;
	}

	.home-container02 {
		grid-template-columns: 1fr 1fr;
	}

	.home-features-sub-heading {
		text-align: center;
		padding-left: var(--dl-space-space-threeunits);
		padding-right: var(--dl-space-space-threeunits);
	}

	.home-text34 {
		text-align: center;
	}

	.home-text35 {
		text-align: center;
	}
}

@media(max-width: 767px) {
	.home-hero1 {
		padding-left: var(--dl-space-space-twounits);
		padding-right: var(--dl-space-space-twounits);
	}

	.video-accueil {
		height: 26vh;
		padding: 2%;
	}

	.home-hero-sub-heading {
		padding-left: var(--dl-space-space-unit);
		padding-right: var(--dl-space-space-unit);
	}

	.home-features {
		padding-left: var(--dl-space-space-twounits);
		padding-right: var(--dl-space-space-twounits);
	}

	.home-text11 {
		font-size: xx-large;
	}

	.home-features2 {
		padding-left: var(--dl-space-space-twounits);
		padding-right: var(--dl-space-space-twounits);
	}

	.home-features-sub-heading {
		padding-left: var(--dl-space-space-unit);
		padding-right: var(--dl-space-space-unit);
	}

	.home-container04 {
		grid-template-columns: 1fr;
		grid-gap: var(--dl-space-space-halfunit);
	}

	.home-steps {
		padding-left: var(--dl-space-space-twounits);
		padding-right: var(--dl-space-space-twounits);
	}

	.home-container05 {
		align-items: flex-start;
		padding-left: var(--dl-space-space-fiveunits);
		flex-direction: column;
	}

	.home-step {
		width: 100%;
		height: auto;
		flex-direction: row;
		justify-content: space-evenly;
	}
}

@media(max-width: 479px) {
	.home-hero1 {
		padding-top: var(--dl-space-space-twounits);
		padding-left: var(--dl-space-space-unit);
		padding-right: var(--dl-space-space-unit);
		padding-bottom: var(--dl-space-space-twounits);
	}

	.home-container01 {
		margin-bottom: var(--dl-space-space-unit);
	}

	.home-features {
		padding-top: 0px;
		padding-left: var(--dl-space-space-unit);
		padding-right: var(--dl-space-space-unit);
		padding-bottom: var(--dl-space-space-twounits);
	}

	.home-features2 {
		padding-top: var(--dl-space-space-twounits);
		padding-left: var(--dl-space-space-unit);
		padding-right: var(--dl-space-space-unit);
		padding-bottom: var(--dl-space-space-twounits);
	}

	.home-steps {
		padding-top: var(--dl-space-space-twounits);
		padding-left: var(--dl-space-space-unit);
		padding-right: var(--dl-space-space-unit);
		padding-bottom: var(--dl-space-space-twounits);
	}

	.home-container05 {
		padding-left: 0px;
	}
}