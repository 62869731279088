.formation-page-card-container {
	width: 100%;
	height: auto;
	display: flex;
	position: relative;
	align-items: center;
	flex-direction: column;
	padding-bottom: var(--dl-space-space-oneandhalfunits);
}

.formation-page-card-blog-post-card {
	width: 100%;
	display: flex;
	overflow: hidden;
	max-width: var(--dl-size-size-maxwidth);
	box-shadow: 5px 5px 5px 0px rgba(18, 18, 18, 0.1);
	transition: 0.3s;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.formation-page-card-blog-post-card:hover {
	transform: scale(1.02);
	cursor: pointer;
}

.formation-page-card-image {
	width: 100%;
	height: 40vh;
	object-fit: cover;
	object-position: center;
}

.formation-page-card-container1 {
	display: flex;
	padding: var(--dl-space-space-twounits);
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
}

.formation-page-card-text {
	color: var(--dl-color-gray-500);
	font-weight: 700;
	margin-bottom: var(--dl-space-space-halfunit);
	text-transform: uppercase;
}

.formation-page-card-text1 {
	font-size: 2rem;
	font-family: Bebas Neue;
	margin-bottom: var(--dl-space-space-twounits);
}

.formation-page-card-text2 {
	color: var(--dl-color-gray-500);
	font-weight: 400;
	margin-bottom: var(--dl-space-space-twounits);
}

.formation-page-card-container2 {
	width: 100%;
	height: auto;
	display: flex;
	align-self: flex-end;
	align-items: center;
	padding-top: 0px;
	border-color: var(--dl-color-gray-900);
	border-width: 1px;
	flex-direction: row;
	justify-content: center;
	border-left-width: 0px;
	border-right-width: 0px;
	border-bottom-width: 0px;
}

.formation-page-card-button {
	align-self: flex-end;
}

.btn-download {
	background-color: var(--dl-color-primary-myblue) !important;
	border: 0;
	color: white !important;
}

@media(max-width: 991px) {

	.formation-page-card-container1 {
		padding: var(--dl-space-space-unit);
	}

	.formation-page-card-text1 {
		margin-bottom: var(--dl-space-space-halfunit);
		font-size: 1.3rem;
	}

	.formation-page-card-image {
		height: 15vh;
		object-fit: fill;
	}
}