.btn-tab-class {
	background-color: #BABABA !important;
	color: black !important;
	font-weight: bold !important;
	height: 100%;
	text-transform: uppercase !important;
}

.btn-tab-class.active {
	background-color: grey !important;
	color: white !important;
	font-weight: bold;
}

.mission-title-div {
	background: url() !important;
	padding: 3% 10%;
}


.tabs-div {
	margin: 0% 10% 0% 10%;
}

.main-div-mission {
	height: auto;
	min-height: 55vh;
	width: 100%;
}

.image-mission {
	height: 30vh;
	width: 100%;
	background-position: center;
	background-size: cover;
	margin-bottom: 1vh;
}

@media (max-width: 768px) {
	.nav-item {
		flex-basis: content !important;
	}

	.mission-title-div {
		padding: 3% 3% !important;
	}

	.tabs-div {
		margin: 0% 0% 0% 0%;
	}
}

h3 {
	margin: 2% 0% !important;
}

#corpsUnique {
	margin: 0 5% !important;
}

#corpsUnique h2 {
	margin: 0 0 2% 0 !important;
}

.div-content {
	margin: 0% 10% 0% 10%;
	border: 1px solid #ddd;
	border-top: none;
	border-radius: 0 0 10px 10px;
	padding: 2% 1% 1% 1%;
}

.div-content-diag {
	margin: 0% 0% 0% 0%;
	border: 1px solid #ddd;
	border-top: none;
	border-radius: 0 0 10px 10px;
	padding: 2% 1% 1% 1%;
}

@media (max-width: 1000px) {
	.div-content {
		margin: 0% 1% 0% 1%;
	}

}