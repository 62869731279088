.faq-row-container {
	gap: var(--dl-space-space-unit);
	flex: 1;
	width: auto;
	height: auto;
	display: flex;
	position: relative;
	align-items: flex-start;
	flex-direction: column;
}

.faq-row-text {
	font-weight: 700;
}