:root {
	--dl-color-gray-500: #595959;
	--dl-color-gray-700: #999999;
	--dl-color-gray-900: #D9D9D9;
	--dl-size-size-large: 144px;
	--dl-size-size-small: 48px;
	--dl-color-danger-300: #A22020;
	--dl-color-danger-500: #BF2626;
	--dl-color-danger-700: #E14747;
	--dl-color-gray-black: #000000;
	--dl-color-gray-white: #FFFFFF;
	--dl-size-size-medium: 96px;
	--dl-size-size-xlarge: 192px;
	--dl-size-size-xsmall: 16px;
	--dl-space-space-unit: 16px;
	--dl-color-primary-100: #003EB3;
	--dl-color-primary-300: #0074F0;
	--dl-color-primary-500: #14A9FF;
	--dl-color-primary-700: #85DCFF;
	--dl-color-success-300: #199033;
	--dl-color-success-500: #32A94C;
	--dl-color-success-700: #4CC366;
	--dl-size-size-xxlarge: 288px;
	--dl-size-size-maxwidth: 1400px;
	--dl-radius-radius-round: 50%;
	--dl-color-custom-accent1: #5D576B;
	--dl-color-primary-myblue: #1d9bb2ff;
	--dl-space-space-halfunit: 8px;
	--dl-space-space-sixunits: 96px;
	--dl-space-space-twounits: 32px;
	--dl-color-custom-primary1: #ED6A5A;
	--dl-color-custom-primary2: #879392;
	--dl-radius-radius-radius2: 2px;
	--dl-radius-radius-radius4: 4px;
	--dl-radius-radius-radius8: 8px;
	--dl-space-space-fiveunits: 80px;
	--dl-space-space-fourunits: 64px;
	--dl-color-primary-reduserd: #b20229ff;
	--dl-space-space-threeunits: 48px;
	--dl-color-custom-secondary1: #E6EBE0;
	--dl-color-custom-neutral-dark: #000000;
	--dl-color-custom-neutral-light: #FFFFFF;
	--dl-space-space-oneandhalfunits: 24px;
}

.button {
	color: var(--dl-color-gray-black);
	display: inline-block;
	padding: 0.5rem 1rem;
	border-radius: var(--dl-radius-radius-radius8);
	background-color: var(--dl-color-gray-white);
}

.featuresContainer {
	flex: 0 0 auto;
	color: var(--dl-color-custom-neutral-light);
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color: var(--dl-color-gray-500);
}

.featuresCard {
	width: 100%;
	display: flex;
	padding: var(--dl-space-space-oneandhalfunits);
	align-items: flex-start;
	flex-direction: row;
	justify-content: flex-start;
	background-color: rgba(255, 255, 255, 0.05);
}

.faqContainer {
	flex: 0 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	background-color: var(--dl-color-custom-secondary1);
}

.heroContainer {
	width: 100%;
	display: flex;
	height: auto;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color: var(--dl-color-custom-neutral-light);
}

.footerContainer {
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: var(--dl-space-space-threeunits);
	padding-right: var(--dl-space-space-threeunits);
	flex-direction: column;
	padding-bottom: var(--dl-space-space-twounits);
	justify-content: space-between;
	background-color: var(--dl-color-custom-neutral-light);
}

.socialIcons {
	fill: var(--dl-color-custom-neutral-dark);
}

.heading2 {
	font-size: 30px;
	font-family: 'Raleway';
	font-weight: 600;
	line-height: 150%;
}

.heading3 {
	font-size: 20px;
	text-align: center;
	font-family: Raleway;
	font-weight: 600;
	line-height: 150%;
}

.bodySmall {
	font-size: 16px;
	font-family: Lato;
	font-weight: 400;
	line-height: 150%;
}

.bodyLarge2 {
	font-size: 18px;
	font-family: Lato;
	font-weight: 400;
	line-height: 150%;
}

.bodyLarge {
	font-size: 18px;
	font-family: Lato;
	font-weight: 400;
	line-height: 150%;
}

ul {
	text-align: left;
}

.accordion-button:not(.collapsed) {
	color: #FFFFFF;
}

.form-label {
	font-weight: bold;
}

.page-title {
	font-size: 46px;
	font-weight: 600;
	color: var(--dl-color-primary-reduserd);
}

.partenaire-image {
	height: 150px;
}

.only_mobile {
	display: none !important;
}

.formations-container {
	width: 100%;
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	align-items: center;
	flex-direction: column;
}

.video-centre {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.text-centre {
	text-align: center;
	padding: 2%;
}

@media (max-width: 1200px) {
	.partenaire-image {
		height: 100px;
	}
}

@media (max-width: 860px) {
	.only_mobile {
		display: block !important;
	}

	.video-centre {
		flex-direction: column;
	}

	.heroContainer {
		margin: 2%;
	}

	.hide-mobile {
		display: none !important;
	}

	.formation-welcome-card-feature-card {
		padding: 0 !important;
	}

	.page-title {
		font-size: 22px;
	}

	.bodyLarge {
		display: none;
	}
}