.contact-container {
	width: 100%;
	display: flex;
	overflow: auto;
	min-height: 100vh;
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-end;
}

.contact-container1 {
	flex: 0 0 auto;
	width: 100%;
	display: flex;
	margin-top: var(--dl-space-space-fourunits);
	align-items: flex-start;
	flex-direction: column;
}

.contact-container2 {
	flex: 0 0 auto;
	display: flex;
	padding: var(--dl-space-space-twounits);
	align-self: stretch;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.contact-text {
	padding-bottom: var(--dl-space-space-unit);
}

.contact-form {
	width: 100%;
	height: auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: self-start;
}

.contact-faq {
	flex: 0 0 auto;
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.contact-faq-container {
	align-items: center;
	justify-content: center;
}

.contact-faq1 {
	gap: var(--dl-space-space-twounits);
	width: 100%;
	display: flex;
	max-width: var(--dl-size-size-maxwidth);
	align-items: flex-start;
	padding-top: var(--dl-space-space-fourunits);
	padding-left: var(--dl-space-space-threeunits);
	padding-right: var(--dl-space-space-threeunits);
	flex-direction: row;
	padding-bottom: var(--dl-space-space-fourunits);
}

.contact-container5 {
	display: flex;
	max-width: 35%;
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
}

.contact-container6 {
	gap: var(--dl-space-space-oneandhalfunits);
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

@media(max-width: 767px) {
	.contact-faq1 {
		flex-direction: column;
	}

	.contact-container5 {
		max-width: 100%;
		align-self: center;
		align-items: center;
		justify-content: center;
	}

	.contact-form {
		grid-template-columns: 1fr;
	}

	#column-msg {
		margin-left: 0% !important;
	}

	#column-fields {
		width: 100% !important;
		margin-right: 0% !important;
	}
}