.logo_img {
	width: 90%;
	content: url("/logo.png");
}

.navbar_logo_div {
	width: 37%;
}

.phone-text:hover {
	cursor: pointer;
}

.phone-text {
	text-decoration: none;
	text-wrap: nowrap;
}

.logo_img:hover {
	cursor: pointer;
}

.nav-link {
	font-weight: 500 !important;
}

.whatsapp-icon {
	height: 22px;
	width: 22px;
}

.num_mobile {
	display: none;
}

.num-end-navbar {
	display: flex !important;
	flex-direction: column !important;
}
@media (min-width: 991px){
	.navbar{
		height: 11vh;
	}
}


@media (max-width: 991px) {
	.navbar_logo_div {
		width: 63%;
		margin-right: 3em !important;
	}

	.logo_img {
		content: url("/logo_af.png");
	}

	.num_mobile {
		display: flex !important;
		flex-direction: row;
		width: 100%;
		justify-content: space-evenly;
	}

	.num-end-navbar {
		display: none !important;
	}

	.phone-text {
		font-family: 'Fjalla One';
	}
}

@media (max-width: 767px) {
	.navbar_logo_div {
		width: 65%;
		margin-right: 6% !important;
	}
}