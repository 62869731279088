.logo_clients {
	width: 90%;
	height: 70vh;
}

.content-avis {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1em;
	margin-right: 5%;
	margin-left: 5%;
}

.divider-avis {
	display: flex;
	justify-content: center;
}

@media (max-width: 768px) {
	#page-content {
		flex-direction: column !important;
	}

	.content-avis {
		grid-template-columns: 1fr 1fr;
		margin-right: 2%;
		margin-left: 2%;
	}

	.logo_clients {
		width: 100%;
		height: 30vh;
	}

}