.formations-container {
	width: 100%;
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	align-items: center;
	flex-direction: column;
}

.formations-container1 {
	width: 100%;
	height: 55px;
	display: flex;
	position: relative;
	margin-top: var(--dl-space-space-fiveunits);
	align-items: flex-start;
	padding-top: var(--dl-space-space-unit);
	padding-bottom: var(--dl-space-space-fiveunits);
}

.formations-text {
	margin: auto;
	align-self: center;
	font-style: normal;
	text-align: center;
}

.formations-container2 {
	width: 100%;
	padding-left: var(--dl-space-space-threeunits);
	padding-right: var(--dl-space-space-threeunits);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: var(--dl-space-space-unit);
}

@media (max-width: 768px) {
	.formations-container2 {
		padding-left: var(--dl-space-space-unit);
		padding-right: var(--dl-space-space-unit);
		grid-template-columns: 47% 47%;
	}

	.formations-text {
		font-size: 23px;
	}
}