.access-container {
    background-color: var(--dl-color-primary-myblue);
    color: white;
    padding: 5%;
    border-radius: 1em;
    font-family: Arial, sans-serif;
    max-width: 90%;
    margin: 2% auto;
  }
  
  h2 {
    text-align: center;
    font-size: 2em;
    color: #ffffff;
    margin-bottom: 5%;
  }
  
  .access-line {
    display: flex;
    align-items: center;
    gap: 5%;
    margin-bottom: 3%;
  }
  
  .icon {
    font-size: 2em; /* Taille de l'icône adaptative */
    color: #ffffff;
    flex-shrink: 0; /* Empêche l'icône de se réduire */
  }
  
  p {
    margin: 0;
    font-size: 1em; /* Taille de texte relative */
    line-height: 1.5;
    flex: 1; /* Permet au texte de s'adapter à l'espace disponible */
  }
  
  @media screen and (max-width: 768px) {
    .access-container {
      padding: 8%;
    }
  
    h2 {
      font-size: 1.5em;
    }
  
    .icon {
      font-size: 1.8em;
    }
  
    p {
      font-size: 1em;
    }
  }
  