.accordions-div {
	width: 65%;
	margin-right: 4%;
	margin-left: 4%;
}

.text-div {
	width: 30%;
	margin-right: 3%;
	margin-left: 3%;
}

.content-div {
	display: flex;
	flex-direction: row;
	width: 100%;
	min-height: 75vh;
}

.title-text {
	color: var(--dl-color-primary-reduserd);
	font-size: 48px;
	font-family: "Lato";
	font-weight: 700;
}

.div-certif-download {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.row-certification {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 70%;
	padding: 3%;
}

.certif-icon {
	width: 100%;
	display: flex;
	align-self: center;
}

@media (max-width: 768px) {
	.accordions-div {
		width: 90%;
		margin-bottom: 5%;
	}

	.titre-formation {
		margin-right: 4%;
		margin-left: 4%;
		justify-content: start !important;
		width: 90% !important;
	}

	.text-div {
		width: 90%;
	}

	.content-div {
		display: flex;
		flex-direction: column;
	}

	.title-text {
		font-size: x-large !important;
	}

	.div-certif-download {
		flex-direction: column;
	}

	.certif-icon {
		width: 100%;
	}

	.row-certification {
		width: 100%;
	}
}